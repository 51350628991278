@import-normalize;

body {
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

::placeholder {
  color: #16056b;
  opacity: 0.25;
}

body, * {
  font-family: 'Tilt Warp', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

#root {
  text-align: center;
  padding: 1em;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  header {
    font-size: 4em;
  }

  header, h1 {
    font-weight: normal;
    color: #16056b;
  }

  h1 {
    font-size: 1.4em;
  }

  ul {
    width: 100%;
    max-width: 400px;
    list-style: none;
    margin: 0 auto;
    padding: 0;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5em;

      &.option-image {
        max-height: 200px;

        .image {
          width: 100%;
          height: 100%;
          max-height: 200px;
          display: flex;
          padding: 1em;
          border-radius: 4px;
          border: 2px solid;
          border-color: #16056b;
          flex-direction: column;

          .image-progress {
            width: fit-content;
            max-height: 100%;
            margin: 0 auto;
            position: relative;

            img {
              max-width: 100%;
              max-height: calc(200px - 2em);
              margin: 0 auto;
            }

            .image-upload-progress {
              top: 0;
              left: 0;
              display: flex;
              flex-direction: row-reverse;
              position: absolute;
              width: 100%;
              height: 100%;

              &.incomplete {
                &:before {
                  content: '⌛';
                  display: block;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  font-size: 2em;
                  transform: translate(-50%, -50%);
                }
              }

              .image-upload-progress-bar {
                background: rgba(255, 255, 255, 0.75);
                height: 100%;
              }
            }
          }
        }
      }

      button.option {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 8px;
        width: 100%;
        margin-bottom: 1em;
        border: 1px solid #f1f1f1;
        position: relative;

        .option-label {
          img {
            max-width: 100%;
          }
        }

        &:not(.voted):hover {
          background: #f1f1f1;
        }

        &.voted {
          cursor: default;

          span.option-label {
            padding-bottom: 0;
          }
        }

        &.selected {
          border-color: #de8d06;
        }

        span {
          order: 1;
          display: block;
          padding: 1em;
          font-size: 1.2em;
          z-index: 3;
          position: relative;
        }

        .progress-bar {
          background: #de8d06;
          height: 100%;
          position: absolute;
          z-index: 2;
          width: 0;
          left: 0;
          transition: width 0.45s ease-out;
        }

        .votes {
          order: 2;
          font-size: 0.8em;
          padding-bottom: 1em;
          display: flex;
          justify-content: center;
          position: relative;
          z-index: 3;
        }
      }
    }
  }

  dl, dt, dd {
    margin: 0;
    padding: 0;
  }

  dl {
    margin-bottom: 2em;
  }

  .add-options {
    display: flex;
    justify-content: center;
  }

  .add-option, .remove-vote {
    color: #16056b;
    display: block;
    cursor: pointer;
    padding: 0.75em;
    background: none;
    border: none;
  }

  .add-option {
    display: inline-flex;
    text-decoration: underline;
  }

  .remove-vote {
    margin: 1.5em auto 0;
  }

  #poll {
    margin-bottom: 2em;
  }

  form {
    width: 400px;
    margin: 0 auto;
    max-width: 100%;

    input {
      font-size: 1.4em;
      padding: 0.5em;
      width: 100%;
      text-align: center;
      border-radius: 4px;
      border: 2px solid;
      border-color: #16056b;

      &:active {
        border-color: #de8d06;
      }
    }

    .delete-option {
      padding: 0;
      background: none;
      border: none;
      font-size: 1em;
      display: flex;
      cursor: pointer;
      margin-left: 0.75em;
    }

    button {
      padding: 18px 24px;
      color: #fff;
      background: #de8d06;
      border: none;
      font-size: 1.4em;
      border-radius: 8px;

      &[disabled] {
        opacity: 0.65;
        cursor: not-allowed;
      }
    }
  }

  aside.meta {
    margin: auto auto 0 auto;
    padding-bottom: 2em;
    display: flex;

    .share-button, .create-poll {
      cursor: pointer;
      background: none;
      color: #16056b;
      max-width: 400px;
      border-radius: 8px;
      border: 2px solid #16056b;
      padding: 8px 12px;
      margin: 0 0.5em;

      @media screen and (min-width: 360px) {
        padding: 12px 18px;
        font-size: 1.1em;
      }

      @media screen and (min-width: 480px) {
        padding: 18px 24px;
        font-size: 1.2em;
      }
    }

    .create-poll {
      color: #de8d06;
      border-color: #de8d06;
    }
  }

  .rws-header {
    font-weight: normal;
  }
}

.dropzone {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  color: #fff;
  display: flex;
  background: #de8d06;
  justify-content: center;
  align-items: center;
  transition: opacity 0.15s ease-in-out;
  position: absolute;
  pointer-events: none;
}

body.dragging {
  .dropzone {
    opacity: 1;
    pointer-events: all;
  }
}
